import React from 'react';
import { AltSectionHeader, Highlight } from '../../../../components/typography';
import DocumentWithCheck from '../../../../images/icons/document-with-checkmark.svg';
import DollarBills from '../../../../images/icons/dollar-bills.svg';
import OverlappingScreens from '../../../../images/icons/overlapping-screens.svg';

export const valueProps = [
  {
    Image: {
      svg: DollarBills,
      imageName: 'dollar-bills.svg',
      height: 85
    },
    data: {
      catchphrase: (
        <AltSectionHeader>
          <Highlight>Honest</Highlight>
          <br />
          about money
        </AltSectionHeader>
      ),
      label: 'honesty',
      text: 'We don’t make more money on higher rates. We get paid the same fee (.40%) by our lenders when you close your loan.'
    }
  },
  {
    Image: {
      svg: OverlappingScreens,
      imageName: 'overlapping-screens.svg',
      height: 85
    },
    data: {
      catchphrase: (
        <AltSectionHeader>
          Radically
          <br />
          <Highlight>transparent</Highlight>
        </AltSectionHeader>
      ),
      label: 'transparency',
      text: 'We flip our screen so you see every option we see — and everyone is treated equally.'
    }
  },
  {
    Image: {
      svg: DocumentWithCheck,
      imageName: 'document-with-checkmark.svg',
      height: 85
    },
    data: {
      catchphrase: (
        <AltSectionHeader>
          <Highlight>Simple</Highlight>
          <br />
          to use
        </AltSectionHeader>
      ),
      label: 'simplicity',
      text: 'It pays to shop around — rates can vary up to .50%. Our tools help you easily compare offers and ensure fair terms.'
    }
  }
];
