import { ValueProps } from '@rategravity/marketing-components';
import { createOwnUpComponent, createOwnUpStyle } from '@rategravity/own-up-component-library';
import React from 'react';
import { valueProps } from '../../../data/content/home/value-prop';
import { metadata } from '../../../data/metadata/images';
import { CallToActionButton } from '../../button';
import { LinkWrapper, OwnUpLink } from '../../links';
import { ValuePropsWrapper } from '../../wrappers';

const SectionStyle = createOwnUpStyle({
  padding: '80px 40px',
  variants: {
    smallAndDown: {
      padding: '10px 40px 60px'
    }
  }
});

const Section = createOwnUpComponent('section', SectionStyle);

export const ValuePropSection = () => (
  <Section aria-label="Own Up's value proposition">
    <ValuePropsWrapper>
      <ValueProps valueProps={valueProps} metadata={metadata} />
    </ValuePropsWrapper>
    <LinkWrapper>
      <OwnUpLink
        link="/how-it-works/"
        label="Own Up How It Works Page"
        mixpanelEvent={{
          name: 'Click how it works CTA',
          properties: { 'page-location': 'home-learn-how' }
        }}
      >
        <CallToActionButton>LEARN HOW</CallToActionButton>
      </OwnUpLink>
    </LinkWrapper>
  </Section>
);
